// form to upolad image / video / pdf
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const VrapForm = ({ setLoading, sessions, activeRecordId, sessionId,
  setSendFile, dispatchAction, acceptFormat, formLabel }) => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.user.token)
  const [fileToUpload, setFileToUpload] = useState()

  const sendFile = (e) => {
    e.preventDefault()
    if(fileToUpload){
      setLoading(true) // to show loader
      dispatch(dispatchAction(token, sessionId, activeRecordId, fileToUpload, sessions))
      setLoading(false)
      setSendFile(false)
    } else {alert('Please select file first')}
  }

  return(
    <div className="vrap-parameter-form" >
      <form onSubmit={sendFile}>
        <label>{formLabel}</label>
        <hr/>
        <div>
          <input type="file" name="video" accept={acceptFormat} onChange={(e) => setFileToUpload(e.target.files[0])}/>
          <div className = "flex-row-space-btw">
            <button className = "buttonGreen" type="submit">Upload</button>
            <button onClick={ () => setSendFile(false)}>Cancel</button>
          </div>
        </div>
      </form>
    </div>

  )
}

export default VrapForm



