/* eslint-disable no-unused-vars */



import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import IconButton from '../../../../../../components/UI/IconButton/IconButton'
import Poll from '../../../../../../utilities/Poll'
import { vrapGetSingleUrl } from '../../../../services/srvSessionRecord'

const SessionMonitor = ({
  activeRecord, setMouseX, setMouseY, mouseX, setActiveVideoId, readyVideoState, errorSpotted,
  mouseY, time, setTime, activeVideoId, setErrorSpotted, setSizeMainVideo, sizeMainVideo, selectedError,
  play, setPlay
}) => {
  const video = document.getElementById('mainVideo')

  const [videoWidth, setVideoWidth] = useState(video ? video.offsetWidth : window.innerWidth/2)
  const [videoHeight, setVideoHeight] = useState(video ? video.offsetHeight: window.innerHeight/2)
  // const [loadPercentage, setLoadPercentage] = useState('')
  const lastIn = (arr) => arr.at(-1)
  const signedUrls = useSelector(state => state.s3signedUrls)
  const[controls, setControls] = useState(true)

  //const videoHeight = document.getElementById('mainVideo') ? document.getElementById('mainVideo').offsetHeight: 0
  const token = useSelector(state => state.user.token)

  // ------- find out active video url ---------------------------

  let fileUrl
  if(!activeVideoId && activeRecord.videos.length > 0){
    try{
      fileUrl = lastIn(activeRecord.videos).url
    } catch(err){
      console.log(err)
      fileUrl = 'noVideo'

    }
  }
  else if(activeVideoId && activeRecord.videos.length > 0)
    fileUrl = activeRecord.videos.filter(video => video.id === activeVideoId)[0].url
  else fileUrl = 'noVideo'

  // if uploading new file, code fails because signedUrls state is not yet synced with database

  const[fileFromS3, setFileFromS3] = useState('')
  let file
  try{
    // try first to get file from state
    if(signedUrls && signedUrls.length > 0 && fileUrl && fileUrl !== 'noVideo'){
      file = signedUrls.filter(item => item.url === fileUrl)[0].signedUrl}
    else file = undefined
  }catch(error){
    // if it fails lets try to get new signed url for file
    if(fileUrl !== 'noVideo'){
      vrapGetSingleUrl(token, fileUrl).then(response => {
        setFileFromS3(response.data)
      })
        .catch(error => {
          console.log(error)
        })
    } else {
      // if it fails again, set file to be empty
      setFileFromS3('')
    }
    // alert if error in console
    console.log(error)
  }
  // if there is fresh signed url from S3, then use it
  if(fileFromS3) file = fileFromS3


  useEffect(() => {
    setActiveVideoId(
      !activeVideoId && activeRecord.videos.legth > 0 ? lastIn(activeRecord.videos).id
        : activeVideoId
    )
  },[activeRecord])


  const errorCont =  activeVideoId && selectedError && errorSpotted ? activeRecord.videos.filter(video => video.id === activeVideoId)[0].defects.filter(defect => defect._id === selectedError)[0] : ''

  // const activeVideo = activeVideoId ?  activeRecord.videos.filter(video => video.id === activeVideoId)[0] : null
  // console.log('activeVideo', activeVideo )

  //------- Define error by mouse click, get time and current mouse coords
  const headerHeight = document.getElementById('vrap-header') ? document.getElementById('vrap-header').offsetHeight : 0
  const defineErrorHandler = (e) => {
    e.preventDefault()
    setTime(document.getElementById('mainVideo').currentTime)
    setMouseX(e.clientX / videoWidth)
    setMouseY((e.clientY - headerHeight + window.scrollY)/(videoHeight))
    setErrorSpotted(false)
    setPlay(false)
    document.getElementById('mainVideo').pause()
  }

  // useEffect(() => {setLoadPercentage('')},[activeVideoId])

  // -------------- FIND TIME IN VIDEO --------------------------------

  useEffect(() => {
    if(time  && video) video.currentTime = time

    else if(fileUrl !== 'noVideo' && readyVideoState > 3){
      video.currentTime = 0}
  },[time])


  // -------------- FIND TIME IN VIDEO --------------------------------

  useEffect(() => {
    if(video && !play && !video.paused){
      video.pause()
      video.currentTime = time
    }
    setVideoWidth(document.getElementById('mainVideo') ? document.getElementById('mainVideo').offsetWidth : 0)
    setVideoHeight(document.getElementById('mainVideo') ? document.getElementById('mainVideo').offsetHeight: 0)
    if(video && !video.paused){
      setErrorSpotted(false)
      setMouseY(0)
      setMouseX(0)
    }

  }, [Poll(true, 10000, 500)])
  // const Poll = (state, maxRounds, time) => {
  // ------------- PLAY BUTTON HANDLE ----------------------------------

  const playButtonHandle = () => {
    // oscDirection = 0
    if(video){
      if(!play){
        video.play()
        setPlay(true)
      } else {
        video.pause()
        setPlay(false)
      }
      setMouseX(0)
      setMouseY(0)
      setErrorSpotted(true)
    }
  }
  // ------------- REVERSE BUTTON HANDLE -------------

  const reverseHandle = () => {
    video.pause()
    setPlay(false)
    setTime(video.currentTime - 0.04)
    setMouseX(0)
    setMouseY(0)
    setErrorSpotted(true)
  }

  let commentsStyle = { left: '10px', top: '5px' }
  if(video && (video.offsetWidth - video.offsetWidth * mouseX) < 300){
    commentsStyle.left = '-110px' }

  if(video && (video.offsetHeight + headerHeight - window.scrollY/videoHeight) - video.offsetHeight * mouseY < 300){
    commentsStyle.top = document.getElementById('cursorBox') ? -1 * document.getElementById('cursorBox').offsetHeight - 5 + 'px' : '-110px'}


  return(
    <>
      <div className="vrap-video-inner-box">
        {/* {file !== '' && document.getElementById('mainVideo') && document.getElementById('mainVideo').readyState <  1 &&
          <div className="vrap-video-loader-container"><Loader title = "Downloading Image"/></div>
        } */}
        {fileUrl !== 'noVideo' ?
          <>
            {/*--------  VIDEO PLAYBACK ---------------*/}
            <video
              className={'vrap-main-video-preview-' + sizeMainVideo}
              src={file}
              preload="auto"
              controls = {controls}
              id = "mainVideo"
              muted={true}
              loop={true}
              onClick={defineErrorHandler}
            >
            </video>

            {/* ----- DEFECT CURSOR --------------------------- */}
            {/* ----- coords are defined by coordinates ------- */}
            <div
              id="vrap-error-cursor"
              style={{
                left: videoWidth * mouseX -5,
                top: videoHeight * mouseY -5,
                opacity: (mouseX + mouseY) > 0 ? 1 : 0
              }}

            >
              {errorSpotted && errorCont &&
              <div id = 'cursorBox' className="vrap-error-txt-on-screen" style={commentsStyle}>
                <div className='mp-mb-5'><b>{errorCont.errorType}</b></div>
                Class: {errorCont.surfaceClass}<br/>
                {errorCont.errorComments && <><b>Comments</b><br/>{errorCont.errorComments}</>}
              </div>}
            </div>

            { video && video.duration > 0 &&
            // ------ MAX / SHRINK VIEW BUTTON  ------

              <div className = "vrap-video-monitoring-ctrls-container">
                {/* ---------- REVERSE BUTTON ---------- */}
                <IconButton
                  className = 'icon-button-secondary hide-in-mobile'
                  char={sizeMainVideo === 'vrap-videoMain-normal' ? '4' : '3'}
                  text={sizeMainVideo === 'vrap-videoMain-normal' ? 'Enlarge' : 'Shrink'}
                  onClick={ () => {
                    setSizeMainVideo( sizeMainVideo === 'vrap-videoMain-normal' ? 'vrap-videoMain-enlarged' : 'vrap-videoMain-normal')
                    setVideoWidth(window.innerWidth)
                    setVideoHeight(window.innerHeight)
                  }}
                />

                {/* ---------- REVERSE BUTTON ---------- */}
                <IconButton
                  className = "icon-button-secondary"
                  char="q"
                  text="Revind"
                  onClick={ reverseHandle }
                />

                {/* ------------- PLAY BUTTON ------------ */}
                <IconButton
                  className = "icon-button-secondary"
                  char={play ? 'B' : 'r'}
                  onClick={ playButtonHandle }
                  text={play ? 'Play' : 'Pause'}
                />
                <IconButton
                  className = "icon-button-secondary"
                  char="A"
                  text="Ctrls hide/show"
                  onClick={ () => { controls ? setControls(false) : setControls(true) } }
                />
              </div>}
          </>
          // ------ if no video, show infoTxt -------------
          : file === '' &&
            <p>No video set yet</p>

        // ------ if no video not loaded  -------------
        }

      </div>
    </>
  )
}

export default SessionMonitor






