import { io } from 'socket.io-client'

// if the frontend is served from same domain as the server, no need to define URL
// The server URL will be deduced from the window.location object
// const URL = 'ws://localhost:3001'

const socket = io({
  ackTimeout: 5000,
  retries: 24,
  autoConnect: false,
  auth: {
    token: ''
  }
})

export default socket
