import React from 'react'
import './ButtonTxtIcon.css'

const ButtonTxtIcon = ({ icon, className, id, label, onClick, order }) => {


  // by default icon is in left side.
  if(!order){order = 'left'}

  return(
    <div onClick={onClick}>
      <button  id={id}
        className = {' buttonTxtIcon-cont buttonTxtIcon-button ' + className }>
        { order === 'left' ?
          <><span className = "buttonTxt-icon">{icon}</span><span className = "buttontxt"> {label}</span></>
          :
          <><span className = "buttontxt"> {label}</span><span className = "buttonTxt-icon">{icon}</span></>
        }
      </button>
    </div>

  )
}

export default ButtonTxtIcon

