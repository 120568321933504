import React, { useState } from 'react'
import './measuredata.css'
import IconButton from '../../../../../../../components/UI/IconButton/IconButton'
import { postMeasureImage } from '../../../../../reducers/rdc-vraps'
import Loader from '../../../../../../../components/UI/Loader/Loader'
import VrapForm from '../VrapForm'
import getSignedUrls from '../utils/getSignedUrls'


const MeasureData = ({ sizeMeasures, session, setsizeMeasures, sessionId, activeRecordId, sessionType, sessions }) => {

  const[sendFile, setSendFile] = useState(false)
  const [loading, setLoading] = useState(false)

  // data of active record
  const activeRecord = session.vrapSessionRecords.filter( record => record.id === activeRecordId)[0]

  // get signed url using url recorded in db
  const file = getSignedUrls(activeRecord.measuresUrl)

  // !! pdf embedded by object is freesing Safari.
  return(
    <div className="vrap-video-inner-box">
      { sessionType !== 'Monitoring' && !loading && sendFile &&
        <>
          <VrapForm setLoading={setLoading} sessions={sessions} activeRecordId={activeRecordId}
            sessionId = {sessionId} dispatchAction={postMeasureImage} acceptFormat = 'application/pdf'
            setSendFile = {setSendFile} formLabel = 'Choose a PDF file to upload'
          />
        </>

      }
      {
        sessionType !== 'Monitoring' && loading &&
          <Loader title="Uploading PDF"/>
      }

      {activeRecord.measuresUrl !== 'notSet' && !loading ?
        <>
          <iframe border="0" cellSpacing="0" className="vrap-pdf" src={file} width = "100%" height="100%"/>
        </>
        :
        <div className="flex-center" style={{ height: '100%' }}>
          <p>QC report PDF not set yet</p>
        </div>
      }

      <div className={'vrap-session-backButton-bottom'}>
        <IconButton
          className = 'icon-button-secondary hide-in-mobile'
          char={sizeMeasures === 'vrap-measures-normal' ? '5' : '3'}
          text={sizeMeasures === 'vrap-measures-normal' ? 'Enlarge' : 'Shrink'}
          onClick={ () => setsizeMeasures( sizeMeasures === 'vrap-measures-normal' ? 'vrap-measures-enlarged' : 'vrap-measures-normal')}
        />
        { sessionType === 'Production' ?
          <IconButton
            className = 'icon-button-secondary'
            char="1" text="Upload"
            onClick={() => setSendFile(sendFile ? false : true)}
          /> : <div></div>}
      </div>

    </div>


  )
}

export default MeasureData



