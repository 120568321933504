import  {
  vrapDelete,
  vrapNew,
  vrapUpdate,
  vrapsGet,
  paramPropAdd,
  chatPost,
  sioChatPost,
  sioChatDelete
} from '../services/srvVraps'
import {
  vrapRecordNew,
  vrapPutMeasureImage,
  vrapPutProcessVideo,
  vrapPutParameterImage,
  videoAddError,
  vrapPostMainVideo,
  vrapPutMainVideo,
  vrapPutRecord,
  videoDeleteError,
  videoDelete
} from '../services/srvSessionRecord'

const vrapsReducer = (state = null, action) => {
  switch (action.type) {
  case 'SET_VRAPS':
    return action.data
  default: return state
  }
}

// get vraps / sessions
export const getSessions = (token, company) => {
  return async dispatch => {
    let vraps = await vrapsGet(token, company)
    if(vraps.data === null) {
      vraps = { data: 'noAccountYet' }
    }
    dispatch({
      type: 'SET_VRAPS',
      data: vraps.data
    })
  }
}

// UPDATE VRAP

export const updateVrap = (token, body, itemId, items) => {
  return async dispatch => {
    const updatedSession = await vrapUpdate(token, body, itemId)
    const updatedSessions = items.map(session => {
      if(session.id === itemId) {
        return updatedSession.data
      }
      else return session
    })
    dispatch({
      type: 'SET_VRAPS',
      data: updatedSessions
    })
  }
}

// post chat
export const postChat = (token, body, vrapId, items) => {
  return async dispatch => {
    const updatedVrap = await chatPost(token, body, vrapId)
    const vraps = items.map( arrItem => {
      if(arrItem.id === vrapId) {return updatedVrap.data }
      else return arrItem
    }
    )
    dispatch({
      type: 'SET_VRAPS',
      data: vraps
    })

  }
}


// update record basic data (caption, comments)
export const putRecord = (token, vrapId, items, recordBody, recodrId) => {
  return async dispatch => {
    const updatedRecord = await vrapPutRecord(token, recodrId, recordBody)

    const vraps = items.map( vrap => {
      if(vrap.id === vrapId) {

        const updatedRecs = vrap.vrapSessionRecords.map(record => {
          if(record.id === recodrId){
            return updatedRecord.data
          } else return record }
        )
        vrap.vrapSessionRecords = updatedRecs
        return vrap
      } else return vrap
    }
    )
    dispatch({
      type: 'SET_VRAPS',
      data: vraps
    })

  }
}

// socketIO post chat
export const sioPostChat = (socket, token, body, vrapId, items, sendMsg=false) => {
  return async dispatch => {
    let socketRes = ''

    // only updating state if sendMsg is false
    if (sendMsg) {
      socketRes = await sioChatPost(socket, token, body)
    } else { socketRes = 'OK' }

    const vraps = items.map( arrItem => {
      if(arrItem.id === vrapId && !sendMsg) {
        if (socketRes !== 'OK') {
          return arrItem
        }
        let updatedVrap = arrItem
        updatedVrap.vrapSessionChats.push(body)
        return updatedVrap
      }
      else return arrItem
    }
    )
    dispatch({
      type: 'SET_VRAPS',
      data: vraps
    })
  }
}

export const sioDeleteChat = (socket, token, msgId, userId, vrapId, items, sendMsg=false) => {
  return async dispatch => {
    let response = ''

    if (sendMsg) {
      response = await sioChatDelete(socket, token, vrapId, msgId, userId)
    } else { response = 'OK' }

    const vraps = items.map( arrItem => {
      if(arrItem.id === vrapId) {
        if (response !== 'OK') {
          return arrItem
        }
        let updatedVrap = arrItem
        updatedVrap.vrapSessionChats = updatedVrap.vrapSessionChats.filter(obj => obj.id !== msgId)
        return updatedVrap
      }
      else return arrItem
    }
    )
    dispatch({
      type: 'SET_VRAPS',
      data: vraps
    })
  }
}


// new record
export const postNewRecord = (token, vrapId, items, recordBody) => {
  return async dispatch => {
    const updatedVrap = await vrapRecordNew(token, vrapId, recordBody)
    const vraps = items.map( arrItem => {
      if(arrItem.id === vrapId) {
        return updatedVrap.data
      }
      else return arrItem
    }
    )
    dispatch({
      type: 'SET_VRAPS',
      data: vraps
    })

  }
}

// POST NEW ERROR SPOT (DEFECT)
export const addVideoError = (token, body, sessionId, vrapItems, recordId, videoId) => {

  return async dispatch => {
    // srv sends just one record to update
    const updatedVrap = await videoAddError(token, body, recordId, sessionId, videoId)

    // lopping vraps and making change by corresponding id
    const vraps = vrapItems.map( vrapItem => {

      if(vrapItem.id === sessionId) return updatedVrap.data

      // if id doesent corresppond leave it as is
      else return vrapItem
    }
    )
    dispatch({
      type: 'SET_VRAPS',
      data: vraps
    })
  }
}

// DELETE ERROR SPOT (DEFECT)
// (token, recordId, activeVideo.id, errorId))
export const deleteVideoError = (token, recordId, videoId, errorId) => {

  return async dispatch => {
    // srv sends just one record to update
    const updatedVraps = await videoDeleteError(token, recordId, videoId, errorId)
    console.log(updatedVraps)
    dispatch({
      type: 'SET_VRAPS',
      data: updatedVraps && updatedVraps.data
    })
  }
}

// dispatch(deleteVideo(token, activeRecordId, activeVideoId))

export const deleteVideo = (token, activeRecordId, activeVideoId) => {

  return async dispatch => {
    // srv sends just one record to update
    const updatedVraps = await videoDelete(token, activeRecordId, activeVideoId)
    console.log(updatedVraps)
    dispatch({
      type: 'SET_VRAPS',
      data: updatedVraps && updatedVraps.data
    })
  }
}
// new measureImage
export const postMeasureImage = (token, sessionId, recordId, file, items) => {
  return async dispatch => {
    let updatedVrap = await vrapPutMeasureImage(token, sessionId, recordId, file)

    const vraps = items.map( arrItem => {
      if(arrItem.id === sessionId) {
        return updatedVrap.data
      }
      else return arrItem
    }
    )

    dispatch({
      type: 'SET_VRAPS',
      data: vraps
    })

  }
}

// post process video
export const postProcessVideo = (token, sessionId, recordId, file, items) => {
  return async dispatch => {
    let updatedVrap = await vrapPutProcessVideo(token, sessionId, recordId, file)

    const vraps = items.map( arrItem => {
      if(arrItem.id === sessionId) {
        return updatedVrap.data
      }
      else return arrItem
    }
    )

    dispatch({
      type: 'SET_VRAPS',
      data: vraps
    })

  }
}

// post main video
export const postMainVideo = (token, sessionId, recordId, file, items) => {

  return async dispatch => {
    const updatedVrap = await vrapPostMainVideo(token, sessionId, recordId, file)

    const vraps = items.map( arrItem => {
      if(arrItem.id === sessionId) {
        return updatedVrap.data }
      else return arrItem
    }
    )
    dispatch({
      type: 'SET_VRAPS',
      data: vraps
    })

  }
}

// update main video
// vrapPutMainVideo = async (token, recordId, videoId, body)

export const putMainVideo = (token, sessionId, recordId, videoId, items, body) => {
  return async dispatch => {
    const updatedRecord = await vrapPutMainVideo(token, recordId, videoId, body)

    const vraps = items.map( arrItem => {
      if(arrItem.id === sessionId)
      {
        const updateRecords = arrItem.vrapSessionRecords.map(record => {
          if(record.id === recordId)
          {
            return updatedRecord.data
          } else return record
        })
        arrItem.vrapSessionRecords = updateRecords
        return arrItem
      } else return arrItem
    }
    )
    console.log('vraps.rdc: ', vraps)
    dispatch({
      type: 'SET_VRAPS',
      data: vraps
    })

  }
}

// post parameter image
export const postParameterImage = (token, sessionId, recordId, file, items) => {
  return async dispatch => {
    let updatedVrap = await vrapPutParameterImage(token, sessionId, recordId, file)

    const vraps = items.map( arrItem => {
      if(arrItem.id === sessionId) {
        return updatedVrap.data
      }
      else return arrItem
    }
    )
    dispatch({
      type: 'SET_VRAPS',
      data: vraps
    })

  }
}

// post paramProp
export const addParamProp = (token, body, vrapId, items) => {
  return async dispatch => {
    const updatedVrap = await paramPropAdd(token, body, vrapId)
    const vraps = items.map( arrItem => {
      if(arrItem.id === vrapId) {
        return updatedVrap.data
      }
      else return arrItem
    }
    )
    dispatch({
      type: 'SET_VRAPS',
      data: vraps
    })

  }
}

// new vrap
export const addNewVrap = (token, body, items) => {
  return async dispatch => {
    const vrap = await vrapNew(token, body)
    // push new vrap to array of vraps / sessions
    console.log('ctrl-items:', items)
    console.log('vrap.data', vrap.data)
    // const vraps = items.push(vrap.data)
    const vraps = items.concat([vrap.data])
    console.log('vrap.vraps', vraps)
    dispatch({
      type: 'SET_VRAPS',
      data: vraps
    })
  }
}


// delete vrap
export const deleteVrap = (token, id) => {
  return async dispatch => {
    let item = await vrapDelete(token, id)

    dispatch({
      type: 'SET_VRAPS',
      data: item.data
    })
  }
}

// delete unit
export const deleteUnit = (token, id, items) => {
  return async dispatch => {
    let item = await vrapDelete(token, id, items)

    // console logging response to know server is doing the job
    console.log('deleted unit: ', item)

    // we dont need response from server to know the result:
    // lets filter unit away form array of units
    const resp = items.filter(arrItem => arrItem.id !== id)
    dispatch({
      type: 'SET_VRAPS',
      data: resp
    })
  }
}



export default vrapsReducer


