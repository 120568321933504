// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addVideoError } from '../../../../../reducers/rdc-vraps'
import TextArea from '../../../../../../../components/UI/TextArea/TextArea'

const SpotErrorsForm = ({ setSurfaceClass, mouseX, setErrorType, mouseY,
  errorType, surfaceClass, time, setMouseY, setMouseX,
  sessionId, activeRecord, activeVideoId
}) => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.user.token)
  const sessions = useSelector(state => state.vraps)
  const [errorComments, setErrorComments] = useState('')

  // add error handler
  const addErrorHandler = (e) => {
    e.preventDefault()

    // simple validate
    let ok = false
    if(errorType && surfaceClass) ok = true
    else alert('Please fill error type and surface!')

    // send error
    if(ok){
      const body = {
        errorLocation: [ mouseX, mouseY ],
        frame: time,
        errorType: errorType,
        surfaceClass: surfaceClass,
        time: Date(),
        errorComments: errorComments
      }
      // addVideoError = (token, body, sessionId, vrapItems, recordId, videoId) => {
      dispatch(addVideoError(token, body, sessionId, sessions, activeRecord.id, activeVideoId))
      setSurfaceClass()
      setErrorType('')
      setMouseX(0)
      setMouseY(0)
    }
  }

  // hadler to cnacel error form
  const cancelHandler = () => {
    setMouseX(0)
    setMouseY(0)
  }
  const cheaderHeight = document.getElementById('vrap-header') ? document.getElementById('vrap-header').offsetHeight : 0
  const videoHeight = document.getElementById('mainVideo').offsetHeight
  const mouseLocation = (cheaderHeight + videoHeight - 120) * mouseY
  // eslint-disable-next-line no-unused-vars
  const formLocation = document.getElementById('errorformheader') ? document.getElementById('errorformheader').offsetTop : 0
  return(
    <div className="vrap-parameter-form" style={{ top:  mouseLocation - videoHeight - cheaderHeight + 100 + 'px' }}>
      <div id="errorformheader">
        <form className = "" onSubmit={addErrorHandler}>
          <h5 className="mp-ml-5">Mark a new surface defect</h5>
          {/* <p className = "vraps-subtitle"> X: {Math.round(mouseX * 100)}% Y: {Math.round(mouseY * 100)}% | at {Math.round(time * 100) / 100 } s.</p> */}
          {/* <label>Defect type:</label> */}

          <input placeholder = 'Defect type:' value={errorType} onChange={ (e) => {setErrorType(e.target.value)} }/>
          <lable>Comments</lable>
          <TextArea trickers={errorComments} value = {errorComments} onChange={(e) => {setErrorComments(e.target.value)}}/>
          <div className="flex-row flex-start mp-ml-5 mp-mt-10">
          Surface class:
            <div onClick={() => {setSurfaceClass(1)} } className = { surfaceClass === 1 ? 'vrap-class-select-button-selected' : 'vrap-class-select-button'}>1</div>
            <div onClick={() => {setSurfaceClass(2)} } className = { surfaceClass === 2 ? 'vrap-class-select-button-selected' : 'vrap-class-select-button'}>2</div>
            <div onClick={() => {setSurfaceClass(3)} } className = { surfaceClass === 3 ? 'vrap-class-select-button-selected' : 'vrap-class-select-button'}>3</div>
          </div>
          <div className = "buttons-row mp-p-5">
            <button className = "buttonGreen -dark-button" type="submit">Add</button>
            <button onClick= {cancelHandler} className = "button -dark-button" type="button">Cancel</button>
          </div>
        </form>
      </div>
    </div>

  )
}
export default SpotErrorsForm