


import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { postProcessVideo } from '../../../../reducers/rdc-vraps'
import Loader from '../../../../../../components/UI/Loader/Loader'
import IconButton from '../../../../../../components/UI/IconButton/IconButton'
import VrapForm from './VrapForm'
import getSignedUrls from './utils/getSignedUrls'

const Session3Camera = ({ sessionType, sessionId, session, activeRecordId, size2Video, setSize2Video }) => {
  const sessions = useSelector(state => state.vraps)
  const[sendFile, setSendFile] = useState(false)
  const [loading, setLoading] = useState(false)

  // data of active record
  const activeRecord = session.vrapSessionRecords.filter( record => record.id === activeRecordId)[0]

  // get signed url using url recorded in db
  const file = getSignedUrls(activeRecord.processVideoUrl)

  return(
    <>
      <div className="vrap-video-inner-box">
        { sessionType !== 'Monitoring' && !loading && sendFile &&
          <div className="vrap-parameter-form" >
            <VrapForm setLoading={setLoading} sessions={sessions} activeRecordId={activeRecordId}
              sessionId = {sessionId} dispatchAction={postProcessVideo} acceptFormat = 'video/*'
              setSendFile = {setSendFile} formLabel = 'CHOOSE A VIDEO FILE TO UPLOAD'
            />
          </div>
        }
        { activeRecord.processVideoUrl !== 'notSet'
            && <video className="vrap-video-preview" controls src={file} preload="auto"></video>
        }

        {
          sessionType !== 'Monitoring' && loading &&
          <Loader title = "Uploading video" text="This may take up to a few minutes"/>
        }
        {
          sessionType === 'Monitoring' && loading &&
          <Loader title = "Dowloading video"/>
        }
        {
          !loading && activeRecord.processVideoUrl === 'notSet' &&
          <div className="flex-center" style={{ height: '100%' }}>
            <p>Process video not set yet</p>
          </div>
        }
      </div>
      <div className={'vrap-session-backButton'}>
        <IconButton
          className = 'icon-button-secondary hide-in-mobile'
          char={size2Video === 'vrap-video2-normal' ? '4' : '3'}
          text={size2Video === 'vrap-video2-normal' ? 'Enlarge' : 'Shrink'}
          onClick={ () => setSize2Video( size2Video === 'vrap-video2-normal' ? 'vrap-video2-enlarged' : 'vrap-video2-normal')}
        />
        { sessionType === 'Production' ?
          <IconButton
            className = 'icon-button-secondary'
            char="1" text="Upload"
            onClick={() => setSendFile(sendFile ? false : true)}
          /> : <div></div>
        }
      </div>

    </>
  )
}

export default Session3Camera






