/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import TextArea from '../../../../../../components/UI/TextArea/TextArea'
import Button from '../../../../../../components/UI/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { putRecord, updateVrap } from '../../../../reducers/rdc-vraps'
import HelpPop from '../../../../../../components/UI/HelpPop/HelpPop'
const SessionHeader = ({ session, activeRecord, sessions, token }) => {
  const[comments, setComments] = useState(activeRecord.comments)
  const[caption, setCaption] = useState(activeRecord.caption)
  const[showTxtArea, setShowTxtArea] = useState(true)
  const help = useSelector(state => state.help.show)

  // export const updateVrap = (token, body, itemId, items) => {

  const dispatch = useDispatch()
  const confirmHadle = () => {
    alert('This functionality is sill on progress...')
  }
  const commentsHadle = (e) => {
    const body = { comments: comments, caption: caption }
    // export const putRecord = (token, vrapId, items, recordBody, recodrId) => {
    dispatch(putRecord(token, session.id, sessions, body, activeRecord.id))
  }
  // update ne
  useEffect( () => {
    setCaption(activeRecord.caption ? activeRecord.caption : 'Please set title for test run here!')
    setComments(activeRecord.comments ? activeRecord.comments : '')
  }, [activeRecord])

  // dirty way to reset TextArea height when changint to other record
  useEffect( () => {
    setShowTxtArea(false)
    setTimeout( () => {setShowTxtArea(true)}, 5)
  },[activeRecord])

  return(
    <div id = "vrap-session-footer">
      <div className = "flex-row flex-start flex-align-center mp-pt-5 mp-pb-5">
        {help && <HelpPop text="You can edit it by clicking, and then press save button"
          title = 'Title for Test Run'
        />}
        <input className = "vrap-input-caption" value={caption} onChange={ (e) => {setCaption(e.target.value)}}/>
      </div>
      <div className = "flex-row flex-start flex-align-center mp-pt-5 mp-pb-5">

        {help && <HelpPop text="
        If there was anything to comment, e.g. for the next test run, you should save them here.
        If this was the last test run, a final report can also be written here."
        title='Test run comments, these only apply to this test run'/>}
        <label>Test run comments</label>
      </div>


      {/* TextArea = ({ onChange, className, value, trickers, placeholder, onKeyUp=null, disabled = false, autoFocus=false }) */}
      {showTxtArea && <TextArea
        value = {comments}
        onChange={(e) => {setComments(e.target.value)}}
        trickers={[comments, activeRecord]}
      />}
      <div className="mp-mt-20 mp-mb-20">
        <Button className="buttonGreen" label="Save" icon="undo" onClick = {commentsHadle}/>
      </div>
      <h3>Session status ({session.accepted ? 'accepted' : 'in progress'})</h3>
      <p>By clicking the button below, you accept the quality requirements of the product based on the videos.</p>
      <p>Once approval is given, no new videos or other entries can be saved.</p>
      <p>If you want to continue the session after approval, you can start a new session</p>
      <Button className="buttonGreen" label="Confirm the end of the session" icon="undo" onClick = {confirmHadle}/>
    </div>
  )
}
export default SessionHeader
