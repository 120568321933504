import  {
  unitsGet, unitPost, unitDelete, unitUpdate, unitSpin
} from '../services/srvVrapUnits'

const vrapUnitsReducer = (state = null, action) => {
  switch (action.type) {
  case 'SET_UNITS':
    return action.data
  default: return state
  }
}

// spin the unit
export const spinUnit = (token, units, unit, motorOption, recordId) => {
  return async dispatch => {

    // send unit Id and spinnning command for server
    const response = await unitSpin(token, motorOption, unit.id, recordId)

    // update local state of unit with unitSpin value
    const updatedUnits = units.map(u => {

      // if not current unit, return it as it is
      if(unit.id !== u.id){
        return u
      } else {

        // if current unit, update motoroption for state
        let updatedUnit = u
        response
          ? updatedUnit.motorOption =  response.data.motorOption
          : updatedUnit.motorOption =  motorOption

        return updatedUnit
      }
    })
    dispatch({
      type: 'SET_UNITS',
      data: updatedUnits ? updatedUnits : units
    })
  }
}

// update unit hwoptions [depricated]
export const updateHwOptions = (token, units, unit, hwSettings) => {
  return async dispatch => {

    const updatedUnits = units.map(u => {

      let updatedUnit = u
      updatedUnit.hwOptions = {
        'motorOptions': {
          'motorCmd': hwSettings.motorCmd,
          'speed': hwSettings.speed,
          'direction': hwSettings.dir,
          'camUrl': hwSettings.camUrl
        }
      }

      return updatedUnit
    })
    dispatch({
      type: 'SET_UNITS',
      data: updatedUnits
    })
  }
}

// update motorCmd in hwoptions
export const updateMotorCmd = (token, units, unit, motorCmd) => {
  return async dispatch => {

    const updatedUnits = units.map(u => {

      let updatedUnit = u
      switch (motorCmd) {
      case 'left':
        updatedUnit.hwOptions.motorOptions.motorCmd = 'rotate'
        updatedUnit.hwOptions.motorOptions.direction = 'left'
        break
      case 'right':
        updatedUnit.hwOptions.motorOptions.motorCmd = 'rotate'
        updatedUnit.hwOptions.motorOptions.direction = 'right'
        break
      case '--pause':
        updatedUnit.hwOptions.motorOptions.motorCmd = 'stop'
        break
      case 'scan':
        updatedUnit.hwOptions.motorOptions.motorCmd = 'scan'
        break
      }

      return updatedUnit
    })
    dispatch({
      type: 'SET_UNITS',
      data: updatedUnits ? updatedUnits : units
    })
  }
}

// get all units
export const setUnits = (token) => {
  return async dispatch => {
    let units = await unitsGet(token)
    if(units.data === null){units = { data: 'noAccountYet' }}
    dispatch({
      type: 'SET_UNITS',
      data: units.data
    })
  }
}

// update unit
export const updateUnit = (token, body, itemId, items) => {
  console.log('rdc-items: ', items)
  return async dispatch => {
    const updatedUnit = await unitUpdate(token, body, itemId)
    const updatedUnits = items.map(unit => {
      if(unit.id === itemId) {
        return updatedUnit.data
      }
      else return unit
    })
    dispatch({
      type: 'SET_UNITS',
      data: updatedUnits
    })
  }
}

// post unit
export const postUnit = (token, body, units) => {
  return async dispatch => {
    console.log('rd-unit: ', body)
    let unit = await unitPost(token, body)

    // concat new unit to array of units
    const updatedUnits = units.concat([unit.data])
    dispatch({
      type: 'SET_UNITS',
      data: updatedUnits
    })
  }
}

// delete unit
export const deleteUnit = (token, id, units) => {
  return async dispatch => {
    let unit = await unitDelete(token, id, units)

    // console logging response to know server is doing the job
    console.log('deleted unit: ', unit)

    // we dont need response from server to know the result:
    // lets filter unit away form array of units
    const data = units.filter(unit => unit.id !== id)
    dispatch({
      type: 'SET_UNITS',
      data: data
    })
  }
}

export default vrapUnitsReducer


