// Help text for BodyComponents.

import React from 'react'
import { useSelector } from 'react-redux'
import IconButton from '../IconButton/IconButton'
import './HelpText.css'

const HelpText = ({ text, className }) => {
  const help = useSelector (state => state.help && state.help.show)

  return (
    help &&
    <div className={'helpText-container ' + className}>

      <div className = "helpText-">
        <IconButton className="icon-button-secondary" char="S"/>
      </div>
      <p className = 'helpText'>
        {text}
      </p>

    </div>
  )

}

export default HelpText