import axios from 'axios'
import { configurate } from '../../../../../services/configurate'
const baseUrl = '/private/api/vrap'

// --------------------- POST SPIN THE UNIT --------------------------->
// commands for spinning plate
// unitSpin(token, spin, unit.id)

export const unitSpin = async (token, motorOption, unitId, recordId) => {
  try{
    const config = configurate(token)
    const body = {
      motorOption: motorOption,
      recordId: recordId
    }
    let response = await axios.post(baseUrl + '/spin/' + unitId, body,  config)
    return response

  } catch(err){
    console.log('Umhh... error at vrap/spin: ', err)
  }
}

// ----------------------GET UNITS --------------------------->

export const unitsGet = async (token) => {
  try{
    const config = configurate(token)
    let response = await axios.get(baseUrl, config)
    return response

  } catch(err){
    console.log('Umhh... error at vrap/unitsGet: ', err)
  }
}

export const unitPost = async (token, body) => {

  try{
    const config = configurate(token)
    let response = await axios.post(baseUrl, body, config)
    return response

  } catch(err){
    console.log('Umhh... error at vraps/service/vrapNew: ', err)
  }
}

export const unitUpdate = async (token, body, unitId) => {

  try{
    const config = configurate(token)
    let response = await axios.put(baseUrl + '/' + unitId, body, config)
    return response

  } catch(err){
    console.log('Umhh... error at custovrapsmers/service/vrapUpdate: ', err)
  }
}

export const unitDelete = async (token, sessionId) => {

  try{
    const config = configurate(token)
    let response = await axios.delete(baseUrl + '/' + sessionId, config)
    return response

  } catch(err){
    console.log('Umhh... error at custovrapsmers/service/vrapDelete: ', err)
  }
}

