import { login } from '../services/auth'
import { updateUser } from '../services/users'
import storageManager from '../services/storageManager'
import dayjs from 'dayjs'
import socket from '../utilities/socketInit'

const userReducer = (state = null, action) => {
  switch(action.type){
  case 'SET_USER':
    return action.data

  default: return state
  }
}

export const loginUser = (user) => {
  return async dispatch => {
    const loggedInUser = await login(user)
    if(loggedInUser && !loggedInUser.error){
      const now = new Date()
      storageManager.setUser('hku', loggedInUser, dayjs(now).add(7, 'day'))
      dispatch({
        type: 'SET_USER',
        data: loggedInUser
      })
    } else{
      return loggedInUser.error
    }
  }
}

export const logout = () => {
  if (socket.connected) {
    socket.auth.token = ''
    socket.disconnect()
    console.log('disconnected from sio')
  }
  window.localStorage.removeItem('hku')
  return{
    type: 'LOGOUT',
    data: null
  }
}

export const setUser = (user) => {
  return{
    type: 'SET_USER',
    data: user
  }
}

export const updateProfile = (token, user, id, updateMyself) => {
  return async dispatch => {
    const updatedUser = await updateUser(token, user, id)
    updateMyself && dispatch({ type: 'SET_USER', data: updatedUser })
  }
}

export default userReducer
