import React from 'react'
import filters from './filters'
import NewSession from './NewSession'
import UiCard from '../../../../components/UI/UiCard/UiCard'
import Title from '../../../../components/UI/Title/Title'
import HelpText from '../../../../components/UI/HelpText/HelpText'
import IconButton from '../../../../components/UI/IconButton/IconButton'
const AppHeader = ({ searchWord, setSearchWord, selectedID, vraps, setFilterdVraps, setBodyContainer, bodyContainer }) => {


  // -------- handler to apply filters from search form ----------------
  const handleFilter = (event) => {
    setSearchWord(event.target.value.toString())
    setFilterdVraps(filters(vraps, event.target.value.toString()))
  }
  // ----------------------- RETURN PAGE ------------------------------
  return(
    <>
      {
        selectedID === null && bodyContainer !== '0'
          &&
          <><div className='add-item-search-top-bar'>

            {/* ------------- BUTTON - add new appointment  ----------------- */}

            <IconButton
              char="j"
              text = "Add a new session"
              className="icon-button-main"
              onClick={() => { setBodyContainer('0') } } />

            {/* -------------------- SEARCH INPUT  ------------------ */}
            <input className = 'input-cardholder-find theme' value={searchWord} onChange={handleFilter} placeholder='Find' />


            {/* Clear search button */}

            {searchWord !== '' &&
            <IconButton
              char="x"
              size="30px"
              className="icon-button-gray"
              onClick={() => {
                setSearchWord('')
                setFilterdVraps(vraps, '')
              } } />}


          </div>
          <div className = 'flex-center'>
            <Title text="Sessions" icon="k" />
            <HelpText text= {<>
              <p>By tapping the the monitor icon, you can view the session and set surface defect markers in the video. </p>
              <p>By tapping the camera icon, you can make video recors in the session</p>
            </>}/>

          </div>

          </>
      }

      {/* -------------FORM to add new session -------------------*/}
      {
        bodyContainer === '0' &&
          <div>
            <div className='space-30'/>
            <UiCard
              headerTitle = "Start a new session"
              headerInfo = ""
              footerInfo = ""
            >
              <NewSession setFilterdVraps = {setFilterdVraps} setBodyContainer = {setBodyContainer} vraps = {vraps} setSearchWord={setSearchWord}/>
            </UiCard>
          </div>
      }

    </>

  )
}
export default AppHeader