import React, { useEffect, useState } from 'react'
import dateToDayAndTime from '../../../../../../../functions/dateToDayAndTime'
import SpotErrorsForm from './SpotErrorsForm'
import SpottedErrors from './SpottedErrors'
import { useDispatch, useSelector } from 'react-redux'
import { getSessions } from '../../../../../reducers/rdc-vraps'


const ErrorSpotting = ({
  sessionType, mouseX, mouseY, time, setTime, setMouseX, setMouseY, setReadyVideoState, setSelecterError, selectedError,
  activeRecord, sessionId, setActiveVideoId, activeVideoId, errorSpotted, setErrorSpotted, sizeMainVideo, setPlay  }) => {

  const [errorType, setErrorType] = useState('')
  const [surfaceClass, setSurfaceClass] = useState('')
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  // Choose video handler
  const chooseVideoHandler = (e) => {
    setActiveVideoId(e.target.value)
    // set mouse to coords to 0
    setMouseX(0)
    setMouseY(0)
    setReadyVideoState(0)
  }
  // get sessions
  useEffect(() => {
    user && dispatch(getSessions( user.token ))
  }, [])

  // show add error -form or not
  let showForm = false
  if(time !== 0 && ( mouseX + mouseY ) > 0 ) showForm = true
  else if(( mouseX + mouseY ) > 0 ) showForm = true
  else showForm = false

  // find out active video content
  const activeVideos = activeRecord.videos.filter(video => video.id === activeVideoId)
  const activeVideo = activeVideos[activeVideos.length - 1]




  // sort videos by date
  let sortedVideos
  try{
    sortedVideos = activeRecord.videos.length > 1
      ? activeRecord.videos.sort((a, b) => b.date.localeCompare(a.date))
      : activeRecord.videos
  }  catch(error)
  {
    sortedVideos = activeRecord.videos
    console.log(error)
  }
  // edited from:
  // https://www.w3schools.com/howto/howto_js_draggable.asp
  // Make the DIV element draggable:
  document.getElementById('errorform') && dragElement(document.getElementById('errorform'))

  function dragElement(elmnt) {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0
    if (document.getElementById(elmnt.id + 'header')) {
    // if present, the header is where you move the DIV from:
      document.getElementById(elmnt.id + 'header').onmousedown = dragMouseDown
    } else {
    // otherwise, move the DIV from anywhere inside the DIV:
      elmnt.onmousedown = dragMouseDown
    }

    function dragMouseDown(e) {

      // get the mouse cursor position at startup:
      pos3 = e.clientX
      pos4 = e.clientY
      document.onmouseup = closeDragElement
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag
    }
    // when chancing to full screen object can be landed outside of window, so barrier for that:
    if(sizeMainVideo !== 'vrap-videoMain-normal' && (elmnt.offsetTop - pos2) < 0) elmnt.style.top = 0 + 'px'
    function elementDrag(e) {
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX
      pos2 = pos4 - e.clientY
      pos3 = e.clientX
      pos4 = e.clientY
      // set the element's new position:
      elmnt.style.top = (elmnt.offsetTop - pos2) + 'px'
      elmnt.style.left = (elmnt.offsetLeft - pos1) + 'px'
    }

    function closeDragElement() {
    // stop moving when mouse button is released:
      document.onmouseup = null
      document.onmousemove = null
    }
  }


  return(
    <div className="vrap-component-container">


      {sessionType === 'Monitoring' &&
        <div>
          { activeRecord.videos.length > 0 &&
        <form>
          <select onChange={chooseVideoHandler} value={activeVideo && activeVideo.id}>
            {sortedVideos.map(function(video, i) {
              return(
                <option key={video.id} value={video.id}>{activeRecord.videos.length - i}. | {video.caption ? video.caption : 'Untitled video'} | {dateToDayAndTime(video.date)}</option>
              )
            }
            )}
          </select>
        </form>
          }
          <div id="errorform">
            { showForm && !errorSpotted &&

            <SpotErrorsForm
              mouseY = { mouseY }                 mouseX = { mouseX }
              surfaceClass = { surfaceClass }     setErrorType  = { setErrorType }
              setMouseY = { setMouseY }           setMouseX = { setMouseX }
              time = { time }                     sessionId = { sessionId }
              activeRecord = { activeRecord }     errorType = { errorType }
              activeVideoId = { activeVideoId }   setSurfaceClass = { setSurfaceClass }
            />

            }
          </div>
        </div>

      }
      { <SpottedErrors
        activeVideo = {activeVideo}
        setMouseX = {setMouseX}
        setMouseY = {setMouseY}
        setTime = {setTime}
        setErrorSpotted = {setErrorSpotted}
        recordId = {activeRecord.id}
        selectedError = {selectedError}
        setSelecterError = {setSelecterError}
        setPlay = {setPlay}
      />}
    </div>
  )
}

export default ErrorSpotting



