import React from 'react'
import ReactDOM from 'react-dom'
import './styles/root.css'
import './styles/index.css'
import './styles/buttons.css'
import './styles/inputs.css'
import './styles/table.css'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import stores from './store'
import { PersistGate } from 'redux-persist/integration/react'


ReactDOM.render(

  <Provider store={stores.store}>
    <Router>
      <PersistGate loading={null} persistor={stores.persistor}>
        <App />
      </PersistGate>
    </Router>
  </Provider>
  , document.getElementById('root')
)

