import React from 'react'
import { useSelector } from 'react-redux'
import './iconButton.css'
const IconButton = ({ char, onClick, id, className, text, size, type }) => {
  const help = useSelector(state => state.help.show)
  if(!className) {className = ''}
  if(!id) {id = ''}
  if(!size) {size = '35px'}

  // const fontSize = size/3 + 'px'

  return(

    <div onClick={onClick} className='icon-container'>
      <button id={id} type={type} className={'icon-symbol ' + className}><span style={{ fontSize: size }}>{char}</span></button>
      { help === true && <div className="icon-txt" >{text}</div>}
    </div>

  )
}

export default IconButton