import React, { useEffect, useState } from 'react'
import socket from '../../../../../../../utilities/socketInit'

const ConnectionStatus = ({ vrapId }) => {
  const [connected, setConnected] = useState(socket.connected)

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Socket connected')
      setConnected(socket.connected)
    })

    socket.on('disconnect', () => {
      console.log('Socket disconnected')
      socket.emit('chat:joinRoom', vrapId)
      setConnected(socket.connected)
    })

    return () => {
      socket.off('connect')
      socket.off('disconnect')
    }
  }, [])

  // 'debugConButton' onClick function for manually (dis)connecting from/to socketIo server during development
  const debugConnection = () => {
    if (socket.connected) {
      socket.disconnect()
    } else {
      socket.connect()
    }
  }
  void(debugConnection) // 'using' the function, prevents the compiler from complaining about unused values

  return(
    <>
      <label className="sioConnection" style={ connected ?  { color: 'green' }:{ color: 'red' } } >
        { connected ? 'Connected':'Disconnected' }
      </label>
      {/* <button className="debugConButton" onClick={ debugConnection }> { connected ? 'Disconnect':'Connect' } </button> */}
      <br/>
    </>
  )
}

export default ConnectionStatus
