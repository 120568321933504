import axios from 'axios'
import { configurate } from '../../../services/configurate'
const baseUrl = '/private/api/vrapsessionrecord'

// NEW RECORD (test run)
export const vrapRecordNew = async (token, id, recordBody) => {

  try{
    const config = configurate(token)
    const body = {
      ...recordBody,
      parametersUrl: 'netSet',
      measuresUrl: 'netSet',
      processVideoUrl: 'netSet'
    }
    let response = await axios.post(baseUrl + '/' + id, body, config)
    return response

  } catch(err){
    console.log('Umhh... error at vraps/service/vrapRecordNew: ', err)
  }
}

// POST SPOTTED ERROR (defect)
export const videoAddError = async (token, body, recordId, sessionId, videoId) => {

  try{
    const config = configurate(token)

    let response = await axios.put(baseUrl + '/' + sessionId + '/' + recordId + '/' + videoId, body, config)
    return response

  } catch(err){
    console.log('Umhh... error at vraps/service/videoAddError: ', err)
  }
}
// DELETE SPOTTED ERROR (defect)
export const videoDeleteError = async (token, recordId, videoId, errorId) => {

  try{
    const config = configurate(token)

    let response = await axios.delete(baseUrl + '/deleteError/' + recordId + '/' + videoId + '/' + errorId, config)
    return response

  } catch(err){
    console.log('Umhh... error at vraps/service/videoAddError: ', err)
  }
}

// DELETE MAIN VIDEO (in record)
export const videoDelete = async (token, recordId, videoId) => {

  try{
    const config = configurate(token)

    let response = await axios.delete(baseUrl + '/deleteMainVideo/' + recordId + '/' + videoId, config)
    return response

  } catch(err){
    console.log('Umhh... error at vraps/service/videoAddError: ', err)
  }
}

// put measure image to record
// router.put('/measureImage/:sessionId/:recordId', async (req, res) => {
export const vrapPutMeasureImage = async (token, sessionId, recordId, file) => {

  // const conf = { headers: { 'Content-Type': 'multipart/form-data' } }

  try{
    const conf = { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } }

    // Sending file as FormData
    const formData = new FormData()
    formData.append('pdf', file)

    let response = await axios.post(baseUrl + '/measureImage/' + sessionId + '/' + recordId + '/', formData, conf)
    return response

  } catch(err){
    console.log('Umhh... error at vraps/service/vrapPutMeasureImage: ', err)
  }
}

// export const vrapGetMeasureImage = async (token, imageName) => {
//   const config = configurate(token)
//   try{
//     let response = await axios.get(baseUrl + '/measureImage/' + imageName, config)
//     return response

//   } catch(err){
//     console.log('Umhh... error at vraps/service/vrapGetMeasureImage: ', err)
//   }
// }

// vrapPutProcessVideo

export const vrapPutProcessVideo = async (token, sessionId, recordId, file) => {

  // const conf = { headers: { 'Content-Type': 'multipart/form-data' } }

  try{
    const conf = { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'video/*' } }

    // Sending file as FormData
    const formData = new FormData()
    formData.append('video', file)

    let response = await axios.post(baseUrl + '/processVideo/' + sessionId + '/' + recordId + '/', formData, conf)
    return response

  } catch(err){
    console.log('Umhh... error at vraps/service/processVideo: ', err)
  }
}

// GET PROCESS VIDEO
// export const vrapGetProcessVideo = async (token, imageName) => {
//   const config = configurate(token)
//   try{
//     let response = await axios.get(baseUrl + '/processVideo/' + imageName, config)
//     return response

//   } catch(err){
//     console.log('Umhh... error at vraps/service/vrapGetProcessVideo: ', err)
//   }
// }
// GET MANY FILES
// export const s3GetMany = async (token, items) => {
//   const config = configurate(token)
//   try{
//     let response = await axios.get(baseUrl + '/processVideo/' + items, config)
//     return response

//   } catch(err){
//     console.log('Umhh... error at vraps/service/vrapGetProcessVideo: ', err)
//   }
// }


// POST PARAMETER IMAGE

export const vrapPutParameterImage = async (token, sessionId, recordId, file) => {

  try{
    const conf = { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'image/*' } }

    // Sending file as FormData
    const formData = new FormData()
    formData.append('image', file)

    let response = await axios.post(baseUrl + '/parameterImage/' + sessionId + '/' + recordId + '/', formData, conf)
    return response

  } catch(err){
    console.log('Umhh... error at vraps/service/processVideo: ', err)
  }
}

// GET PARAMETER IMAGE
// export const vrapGetParameterImage = async (token, imageName) => {
//   const config = configurate(token)
//   try{
//     let response = await axios.get(baseUrl + '/parameterImage/' + imageName, config)
//     return response

//   } catch(err){
//     console.log('Umhh... error at vraps/service/vrapGetParameterImage: ', err)
//   }
// }


// update main vvideo
// router.put('/mainVideo/:recordId/:videoID', async (req, res) => {...
export const vrapPutMainVideo = async (token, recordId, videoId, body) => {
  try{
    const config = configurate(token)
    let response = await axios.put(baseUrl + '/mainVideo/update/' + recordId + '/' + videoId, body, config)
    return response

  } catch(err){
    console.log('Umhh... error at  update vraps/service/MainVideo: ', err)
  }
}

// vrapPutProcessVideo
// const updatedVrap = await vrapPostMainVideo(token, sessionId, recordId, file)

export const vrapPostMainVideo = async (token, sessionId, recordId, file) => {
  try{
    const conf = { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'video/*' } }

    // Sending file as FormData
    const formData = new FormData()
    formData.append('video', file)

    let response = await axios.post(baseUrl + '/mainVideo/' + sessionId + '/' + recordId + '/',formData, conf)
    return response

  } catch(err){
    console.log('Umhh... error at vraps/service/MainVideo: ', err)
  }
}
// ----------------  UPDATE RECORD comments / caption -------------------------
// router.put('/basicData/:id', async (req, res) => {
export const vrapPutRecord = async (token, recordId, body) => {
  const config = configurate(token)
  try{
    let response = await axios.put(baseUrl + '/basicData/' + recordId, body,  config)
    return response

  } catch(err){
    console.log('Umhh... error at vraps/service/put in record: ', err)
  }
}

// GET SINGLE S3 signed url
export const vrapGetSingleUrl = async (token, fileName) => {
  const config = configurate(token)
  try{
    let response = await axios.get(baseUrl + '/mainVideo/' + fileName, config)
    return response

  } catch(err){
    console.log('Umhh... error at vraps/service/vrapGetMainVideo: ', err)
  }
}






