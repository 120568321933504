import axios from 'axios'
import { configurate } from './configurate'
const baseUrl = '/private/api/aws'

// GET MANY FILES
export const s3GetMany = async (token, body) => {
  console.log('token', token)
  console.log('body', body)
  const config = configurate(token)
  try{
    let response = await axios.post(baseUrl + '/getmany/', body, config)
    return response

  } catch(err){
    console.log('Umhh... error at S3Service/getMany: ', err)
  }
}

// GET ONE FILE

// GET PROCESS VIDEO
export const s3GetSingle = async (token, imageName) => {
  const config = configurate(token)
  try{
    let response = await axios.get(baseUrl + '/getSingle/' + imageName, config)
    return response

  } catch(err){
    console.log('Umhh... error at S3Service/getSingle: ', err)
  }
}