import React from 'react'
import IconButton from '../../../../components/UI/IconButton/IconButton'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const BodyButtons = ({ setBodyContainer, vrap, setSelectedID }) => {
  const history = useHistory()
  const scrollTop = () => {
    window.scrollTo({ top: 0,  behavior: 'smooth' })
  }
  return(
    <div className='body-buttons-container'>
      <IconButton
        className = 'icon-button-secondary'
        char="k" text="moitoring"
        onClick={ () => { history.push('/vraps/A' + vrap.id)}}
      />
      <IconButton
        className = 'icon-button-secondary'
        char="c" text="Production"
        onClick={ () => { history.push('/vraps/B' + vrap.id) }}
      /><div className = 'vrap-body-button-settings'>
        <IconButton
          className = 'icon-button-secondary'
          char="m" text="edit"
          onClick={ () => { setBodyContainer('1'); scrollTop(); setSelectedID(vrap.id)}}
        />
      </div>

    </div>
  )
}

export default BodyButtons