import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './PostManager.css'
import { submitPost, resetPost, submitThread } from '../../MessageReducers/newPostReducer'
import { initializeThreads } from '../../MessageReducers/threadsReducer'
import TitleInput from './TitleInput/TitleInput'
import ImageUploader from './ImageUploader/ImageUploader'
import Attachments from './Attachments/Attachments'
import QuotedPost from './QuotedPost/QuotedPost'
import { setPostBody, addLink } from '../../MessageReducers/newPostReducer'
import { setNotification } from '../../../../components/UI/Notification/notificationReducer'
import IconButton from '../../../../components/UI/IconButton/IconButton'
import { useHistory } from 'react-router-dom'

const PostManager = ({ postTextareaRef, resetUi, newThread, setShowPostManager }) => {

  const dispatch = useDispatch()
  const history = useHistory()
  const post = useSelector(state => state.newPost)
  const user = useSelector(state => state.user)
  const company = user.company
  const currentInputBody = useSelector(state => state.newPost.body)
  const colors = [{ color: 'var(--main-color)' }, { color: 'var(--gray-medium)' }]
  const [submitActive, setSubmitActive] = useState(currentInputBody.length > 0 ? colors[0] : colors[1])


  const postLinks = useSelector(state => state.newPost && state.newPost.links)
  const postImages = useSelector(state => state.newPost && state.newPost.images)
  const onInput = (event) => {
    event.preventDefault()
    !submitActive && setSubmitActive(currentInputBody.length > 0 ? colors[0] : colors[1])
    dispatch(setPostBody(event.target.value))
  }

  const submitInput = (e) => {
    e.preventDefault()
    createNewPost()
  }

  const submitButton =  <IconButton
    char = "l"
    onClick={ (currentInputBody.length > 0 || postLinks.length > 0 || postImages.length > 0) ? submitInput : null }
    className = 'icon-button-gray'
    size="20px"
  />


  // For hiding the image button when link input is shown
  const [showImageButton, setShowImageButton] = useState(true)

  const toggleImageButton = () => {
    setShowImageButton(!showImageButton)
  }

  const validateNewThread = (topic) => {
    return (topic && topic !== '') ? true : false
  }

  // send data to server (new post to topic or answer to post)
  const createNewPost = async () => {

    const newPost = {
      body: post.body ? post.body : ' ',
      images: post.images,
      links: post.links,
      quotes: post.quotes,
      firstname: user.firstname,
      lastname: user.lastname,
      author: user.username,
      company: company
    }
    if(!newThread){
      const url = window.location.href
      const idIndex = url.lastIndexOf('/')
      const threadId = url.substring(idIndex + 1)

      dispatch(submitPost(user.token, newPost, threadId))
      dispatch(resetPost())
    } else {
      if(validateNewThread(post.newThreadTopic)){
        newPost.topic = post.newThreadTopic
        dispatch(submitThread(user.token, newPost))
        dispatch(initializeThreads(user.token))
        dispatch(resetPost())
        resetUi()
      } else {
        dispatch(setNotification({ notification: 'Anna keskustelulle otsikko.', type: 'alert' }))
      }
    }
  }

  //----------------  link input -------------------------------

  const [showInput, setShowInput] = useState(false)


  const [url, setUrl] = useState('')

  // Input is referenced so it can be auto focused when it's rendered
  const inputEl = useRef(null)

  const onChange = (event) => {
    setUrl(event.target.value)
  }

  const urlInput =
  <input
    type="text"
    name="link-input"
    id="url-input"
    placeholder="jaa linkki"
    ref={inputEl}
    onChange={onChange}
    value={url}
  />

  const toggleInput = () => {
    toggleImageButton()
    setShowInput(!showInput)
  }

  const validateLink = (url) => {
    if(url.includes('http://') || url.includes('https://')){
      return url
    } else {
      return 'http://' + url
    }
  }

  const onSubmit = (event) => {
    event.preventDefault()
    const newUrl = validateLink(url)
    dispatch(addLink(newUrl))
    setUrl('')
    toggleInput()
  }

  const submitLinkButton =
    <button
      id="submit-link-button"
      onClick={onSubmit}
    >
    ICON SUBMIT
    </button>

  const newLinkInput = <>{urlInput}{submitLinkButton}</>

  const form =
  <form id="add-link-form">
    {showInput && newLinkInput}
  </form>

  // Auto focusing on link input needs to be done in useEffect, else inputEL.current returns null
  useEffect(() => {
    inputEl.current && inputEl.current.focus()
  }, [showInput])

  const backHandle = () => {
    setShowPostManager(false)
    history.push('/msg')
  }

  return(
    <div id="post-manager-container">
      <div id="post-manager">
        {newThread && <TitleInput/>}
        <div>
          { (post.quotes && post.quotes.length > 0)
            ? <QuotedPost name={post.quotes[post.quotes.length - 1].name} />
            : <h5>Kirjoita viesti</h5>
          }
          <div id="post-input">
            <div>
              <form>
                <textarea
                  id="post-textarea"
                  rows="5"
                  cols="160"
                  value={currentInputBody}
                  onChange={onInput}
                  ref={postTextareaRef}
                ></textarea>
              </form>
            </div>
            <div id="post-input-buttons">

            </div>
          </div>
          <div id="image-and-link-buttons-container">
            <IconButton
              char = "q"
              onClick={backHandle}
              className = 'icon-button-gray'
              size="20px"
            />

            <div id="link-input" >
              <button
                className="icon msg-icon-addlink icon-button"
                onClick={toggleInput}
              />
            </div>
            <div>
              <ImageUploader />
            </div>
            {submitButton}
          </div>
          <div id="link-input-container">
            { form }
            <Attachments/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostManager