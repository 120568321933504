import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postUnit } from '../reducers/rdc-vrapUnits'
import Button from '../../../../../components/UI/Button/Button'

const BodyNewItem = ({ setBodyContainer }) => {

  const dispatch = useDispatch()
  const token = useSelector(state => state.user.token)
  const units = useSelector(state => state.vrapUnits)
  // const companyId = useSelector(state => state.user.company)
  const [name, setName] = useState('')
  const [src1, setSrc1] = useState('')
  const [src2, setSrc2] = useState('')
  const [src3, setSrc3] = useState('')


  // ---------------- new item handler -----------------
  // export const postUnit = (token, body, units) => {
  const handleAddNewItem = (event) => {
    event.preventDefault()
    const body = {
      unitName: name,
      mainVideoSource: src1,
      productionVideoSource: src2,
      parametersVideoSource: src3,
      hwOptions: {
        motorOptions: {
          motorCmd: null,
          speed: 0.005,
          direction: 'right',
          camUrl: 'rtsp:localhost:8554/camera1'
        }
      }
    }
    dispatch(postUnit(token, body, units))
    setBodyContainer('')
  }

  return(
    <>
      <form onSubmit={handleAddNewItem} className="flex-column-container" style={{ marginTop: 0, paddingTop: 0 }}>

        {/* Name */}
        <input
          className="passed"
          placeholder="Name for new Vrap Unit"
          value = {name}
          onChange={(e) => {setName(e.target.value)}}
        />
        {/* Main Vidoa */}
        <input
          className="passed"
          placeholder="Main video source"
          value = {src1}
          onChange={(e) => {setSrc1(e.target.value)}}
        />

        {/* Production Video */}
        <input
          className="passed"
          placeholder="Production video source"
          value = {src2}
          onChange={(e) => {setSrc2(e.target.value)}}
        />


        {/* Paramaters Video */}
        <input
          className="passed"
          placeholder="Parameters video source"
          value = {src3}
          onChange={(e) => {setSrc3(e.target.value)}}
        />

        {/* confirmation, and cancel Buttons */}
        <div className="flex-row-container">
          <Button
            className = "buttonGreen"
            label = 'Tallenna'
            type = 'buttonGreen'
            icon = 'update'
          />

          <div onClick={ () => { setBodyContainer('') }}>
            <Button
              type = "buttonNoEnter"
              label='Peruuta'
              icon='undo'
            />
          </div>
        </div>
      </form>
    </>
  )
}

export default BodyNewItem
