import React from 'react'
import IconButton from '../../../../../../components/UI/IconButton/IconButton'
import dateToDay from '../../../../../../functions/dateToDay'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ButtonTxtIcon from '../../../../../../components/UI/ButtonTxtIcon/ButtonTxtIcon'
import HelpPop from '../../../../../../components/UI/HelpPop/HelpPop'
import { useDispatch, useSelector } from 'react-redux'
import { getSessions } from '../../../../reducers/rdc-vraps'
const SessionHeader = ({ activeRecordId, session, sessionType, activeRecord, setActiveRecordId,
  setActiveVideoId, setModal, setSessionType }) => {
  const history = useHistory()
  function lastIn(arr){ return arr[arr.length-1] }
  const help = useSelector(state => state.help.show)
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  const selectHandle = (e) => {
    setActiveRecordId(e.target.value)
    setActiveVideoId(
      // find out first if there are videos
      session.vrapSessionRecords.filter(record => record.id === e.target.value)[0].videos.length > 0 ?

        lastIn(session.vrapSessionRecords.filter(record => record.id === e.target.value)[0].videos).id: null)
  }
  const reloadVrapsHandler = () => {
    // get sessions
    dispatch(getSessions( user.token ))
  }

  return(
    <div id="vrap-header" className="vrap-layout-grp-header">

      {/*   --------------ADD A NEW TEST RUN BUTTON ------------------------*/}

      <div className='flex-row-column mp-ml-20'>
        <ButtonTxtIcon icon='D'
          onClick={() => setModal(true)}
          className='buttonGreen'
          label = 'Add a new test run'
        />
        {/*  help Pop  */}
        {help && <HelpPop title="Add new test run" text="When you start a new series on a product with new parameters."/>}

        {/*   -------------- SESSION NAME | TEST RUN CAPTION ------------------------*/}

        <div className = 'flex-row'>
          <b>{session.sessionName}</b> {activeRecord && activeRecord.caption && ` ${activeRecord.caption}`}
          <div className='mp-mb-5 flex-row'>

            {/* ------------------- SELECT TESTRUN ----------------------------------*/}

            <select value = {activeRecordId} onChange={selectHandle}>
              {
                session.vrapSessionRecords.map((record, index) => {
                  return(
                    <option value = {record.id} key={record.id}>
                        Test run {index + 1 + ' • ' + dateToDay(record.date)}
                    </option>
                  )})}
            </select>
            {help && <HelpPop
              text="When you open the page, the most recent test run is displayed by default. You can watch older runs here"
              title = "Recorded test runs"
            />}

          </div>
          <IconButton
            char = 'd'
            text= 'Reload'
            onClick={reloadVrapsHandler}
            className = 'icon-button-secondary'
            size="30px"
          />
        </div>

      </div>
      <div className='flex-row mp-p-10'>

        {/*   -------------- LINK TO BOTTOM OF PAGE------------------------*/}
        <IconButton
          char = 'e'
          text= 'Comments'
          onClick={() => {window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' })}}
          className = 'icon-button-secondary'
          size="30px"
        />
        {/*   -------------- Switch to PRODUCTION / MONITORING BUTTON------------------------*/}
        <IconButton
          char = {sessionType === 'Monitoring' ? 'c' : 'k' }
          text= {'Switch to ' + (sessionType === 'Monitoring' ? 'production' : 'monitoring') }
          onClick={() => setSessionType(sessionType === 'Monitoring' ? 'Production' : 'Monitoring')}
          className = 'icon-button-secondary'
          size="30px"
        />
        {/*   -------------- EXIT SESSION BUTTON------------------------*/}
        <IconButton
          char = "x"
          text='Exit session'
          onClick={ () => {history.push('/vraps')} }
          className = 'icon-button-secondary'
          size="30px"
        />
      </div>
    </div>

  )

}
export default SessionHeader