import { useDispatch, useSelector } from 'react-redux'
import { s3GetSignUrlsForLatestSession, s3GetSignedUrl } from '../../../../../../../reducers/s3Reducer'

const getSignedUrls = (fileUrl, session) => {
// if uploading new file, code fails because signedUrls state is not yet synced with database
  const token = useSelector(state => state.user.token)
  const signedUrls = useSelector(state => state.s3signedUrls)

  // const[fileFromS3, setFileFromS3] = useState('')
  let file = null
  const time = new Date().getTime()
  const lifetime = 3600000
  const dispatch = useDispatch()

  // try to find signed url from state fist:
  if(signedUrls && signedUrls.length > 0 && fileUrl && fileUrl !== 'noVideo' && fileUrl !== 'notSet'){

    // filter url from existing state
    try{
      file = signedUrls.filter(item => item.url === fileUrl)[0].signedUrl
    } catch(error){
      // if this fails, it means that new image is sent and there is no url for it yet
      // do ones again, update signed urls
      // dispatch(s3GetSignUrlsForLatestSession(session, token, signedUrls))
      console.log('missing url...')
      dispatch(s3GetSignedUrl(token, fileUrl, signedUrls))
    }
  }


  // if file is undefined it means that it wasnt in state
  if(file && time - file.date > lifetime && fileUrl !== 'noVideo' && fileUrl !== 'notSet'){
    // if url exists, but it is too old, update all sigend urls id needed
    dispatch(s3GetSignUrlsForLatestSession(session, token, signedUrls))
  }


  return file
}

export default getSignedUrls