import React from 'react'
import IconButton from '../../../../../../../components/UI/IconButton/IconButton'
// import HwSettings from '../../../../HWsettings/hwSettings'

import './videoControls.css'
import { useDispatch, useSelector } from 'react-redux'
import { spinUnit, updateMotorCmd } from '../../../../VrapUnits/reducers/rdc-vrapUnits'
const VideoButtons = ({ currentUnit, activeRecordId, sizeMainVideo, setSizeMainVideo, sendFile, setSendFile, hwModal, setHwModal }) => {
  const token = useSelector(state => state.user.token)
  const dispatch = useDispatch()
  const units = useSelector(state => state.vrapUnits)

  const buttonEventHandler = (motorOption) => {
    // token, units, unit, spin
    dispatch(updateMotorCmd(token, units, currentUnit, motorOption))
    dispatch(spinUnit(token, units, currentUnit, currentUnit.hwOptions, activeRecordId))
  }
  return(
    <div className = "vrap-video-control-button-container">
      <IconButton
        className = { currentUnit.motorOption && currentUnit.motorOption === 'left' ? 'icon-button-secondary videoButton-active' : 'icon-button-secondary'}
        char={sizeMainVideo === 'vrap-videoMain-normal' ? '4' : '3'}
        text={sizeMainVideo === 'vrap-videoMain-normal' ? 'Enlarge' : 'Shrink'}
        onClick={ () => setSizeMainVideo( sizeMainVideo === 'vrap-videoMain-normal' ? 'vrap-videoMain-enlarged' : 'vrap-videoMain-normal')}
      />
      <IconButton
        className = { currentUnit.motorOption && currentUnit.motorOption === 'left' ? 'icon-button-secondary videoButton-active' : 'icon-button-secondary'}
        char="q" text="< Rotate"
        onClick={() => buttonEventHandler('left')}
      />
      <IconButton
        className = { currentUnit.motorOption && currentUnit.motorOption === '--pause' ? 'icon-button-secondary videoButton-active' : 'icon-button-secondary'}
        char="B" text="Pause"
        onClick={() => buttonEventHandler('--pause')}
      />
      <IconButton
        className = { currentUnit.motorOption && currentUnit.motorOption === 'right' ? 'icon-button-secondary videoButton-active' : 'icon-button-secondary'}
        char="r" text="Rotate >"
        onClick={() => buttonEventHandler('right')}
      />
      <IconButton
        className = { currentUnit.motorOption && currentUnit.motorOption === '--oscillation 20' ? 'icon-button-secondary videoButton-active' : 'icon-button-secondary'}
        char="c" text="Scan"
        onClick={() => buttonEventHandler('scan')}
      />
      <IconButton
        className = 'icon-button-secondary'
        char="1" text="Upload"
        onClick={() => setSendFile(sendFile ? false : true)}
      />
      <IconButton
        className = 'icon-button-secondary'
        char="m" text='Settings'
        onClick={() => setHwModal(hwModal ? false : true)}
      />
    </div>
  )
}

export default VideoButtons
