// socket io version of vrapChat

import React, { useEffect, useState } from 'react'
import './vrapChat.css'
import { useDispatch, useSelector } from 'react-redux'
import DayAndTime from '../../../../../../../functions/DayAndTime'
import { sioPostChat, sioDeleteChat, getSessions } from '../../../../../reducers/rdc-vraps'
import TextArea from '../../../../../../../components/UI/TextArea/TextArea'
import IconButton from '../../../../../../../components/UI/IconButton/IconButton'
import socket from '../../../../../../../utilities/socketInit'
import ConnectionStatus from './sioConnectionStatus'

// { vrap }
const VrapChat = ({ id }) => {

  const dispatch = useDispatch()
  const vraps = useSelector( state => state.vraps)
  const user = useSelector(state => state.user)
  const vrap = useSelector( state => state.vraps).filter(item => item.id === id)[0]
  const token = useSelector(state => state.user.token)

  // connection to socketIO server when loading session
  useEffect(() => {
    if (socket.disconnected){
      socket.auth.token = token
      socket.connect()
      console.log('no previous connection, connected to sio server')
    }
    socket.emit('chat:joinRoom', vrap.id)
    console.log(`connected to room: ${vrap.id}`)
    return () => {
      socket.emit('chat:leaveRoom')
      console.log(`left room ${vrap.id}`)
    }
  }, [])

  useEffect(() => {
    const onMsgReceive = (value) => {
      console.log(`SIO received msg: ${JSON.stringify(value)}`)
      dispatch(sioPostChat(socket, user.token, value, vrap.id, vraps))
    }

    const onMsgDelete = (value) => {
      console.log(`SIO delete msg: ${JSON.stringify(value)}`)
      dispatch(sioDeleteChat(socket, token, value.msgId, value.userId, vrap.id, vraps))
    }

    const onNewVideo = (payload) => {
      console.log(`new video msg: ${ JSON.stringify(payload) }`)
      dispatch(getSessions(user.token))
    }

    const onConnectionError = (err) => {
      console.log(err.message)
      console.log(err.data)
    }

    socket.on('chat:message', onMsgReceive)
    socket.on('chat:delete', onMsgDelete)
    socket.on('info:newVideo', onNewVideo)
    socket.on('connect_error', onConnectionError)
    return () => {
      socket.off('chat:message', onMsgReceive)
      socket.off('chat:delete', onMsgDelete)
      socket.off('info:newVideo', onNewVideo)
      socket.off('connect_error', onConnectionError)
    }
  }, [])

  const rmMsgHandler = (msgId, userId) => {
    dispatch(sioDeleteChat(socket, token, msgId, userId, vrap.id, vraps, true))
  }

  const ChatList = () => {
    const [showTC, setShowTC] = useState(false)

    return(
      <>
        {vrap.vrapSessionChats.map(item =>
          <div onMouseEnter={() => setShowTC(true)} onMouseLeave={() => setShowTC(false)} key={item.authorId + item.date} className = {
            `vrap-chat-message-conteiner ${item.authorId === user.id ? 'sent-msg' : 'received-msg'}`
          }>
            <div className = {
              `vrap-chat-message-header ${item.authorId === user.id ? 'sent-msg-header' : 'received-msg-header'}`
            }>
              { item.firstname + ' ' + item.lastname} <DayAndTime date = {item.date} />
            </div>
            <div className = "vrap-chat-message-body">
              { item.messageBody}
              { showTC && item.authorId === user.id ?
                <IconButton
                  onClick={ () => rmMsgHandler(item.id, user.id) }
                  char='M'
                  className="icon-button-secondary"
                  size="12px"
                />
                : <none/>
              }
            </div>
          </div>
        )}
      </>
    )
  }

  const NewMessage = () => {
    const [neewMessage, setNewMessage] = useState('')

    useEffect( async () => {
      const storedValue = await localStorage.getItem('chatBuffer')
      if (storedValue) {
        await setNewMessage(storedValue)
      }
    }, [])

    useEffect( async () => {
      await localStorage.setItem('chatBuffer', neewMessage)
    }, [neewMessage])

    // fires on every keypress, if Enter dispatch chat message
    const onKeyUpHandler = (e) => {
      if (e.key === 'Enter') {
        sendHandeler()
      }
    }

    const createUUID = () => {
      try{
        return crypto.randomUUID()
      }catch (e) {
        console.log('Error creating UUID using crypto.randomUUID')
      }

      // all browsers might not support crypto.randomUUID() function
      try {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
          var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
          return v.toString(16)
        })
      } catch (err) {
        console.log('Error creating UUID using Math.random')
        throw new Error('Could not create UUID')
      }

    }

    // send message
    const sendHandeler = () => {
      const date = new Date().toISOString()
      const msg = neewMessage.replace(/(\r\n|\n|\r)/gm, '')
      const body = {
        messageBody: msg,
        date: date,
        firstname: user.firstname,
        lastname: user.lastname,
        authorId: user.id,
        chatRoom: vrap.id,
        msgOffset: createUUID() // used to identify multiple messages sent to socketIo server
      }

      if (msg.length >= 1) {
        setNewMessage('')
        dispatch(sioPostChat(socket, user.token, body, vrap.id, vraps, true))
      } else {
        console.log(`message not sent: no content | msg length: ${msg.length}`)
      }
      localStorage.setItem('chatBuffer', '')
    }

    return(
      <div>
        <ConnectionStatus vrapId={vrap.id}/>
        <label>Join the conversation</label>
        <div className = "vrap-chat-write-area">
          <TextArea
            autoFocus={false}
            onKeyUp = {onKeyUpHandler}
            trickers ={[neewMessage]}
            className = "passed"
            placeholder='Write a message'
            value={neewMessage}
            onChange={(e) => { setNewMessage(e.target.value) }}/>
          <IconButton
            char = 'l'
            size="30px"
            onClick={sendHandeler}
            className="icon-button-main"
          />
        </div>
      </div>
    )
  }
  return(
    <div className = "vrap-component-container vrap-chat-padding">
      <NewMessage/>
      <div className = "vrap-chat-container flex-column-reverse">
        <ChatList/>
      </div>
    </div>
  )
}

export default VrapChat
