// Help Button which changes global state
// {show: boolean} true / false
// you call in every desired place content if help.show is true

import React from 'react'
import { showHelp, hideHelp } from './helpReducer'
import { useSelector, useDispatch } from 'react-redux'
import  './help.css'
import IconButton from '../IconButton/IconButton'

const Help = () => {

  const dispatch = useDispatch()
  const help = useSelector(state => state.help && state.help)

  const helpTogglerHandler = () => {
    help.show === false ? dispatch(showHelp()) : dispatch(hideHelp())
  }

  return(
    <div
      onClick={helpTogglerHandler}
      style={{ position:'relative' }}
      className ="help-button hover-pointer"
    >
      <IconButton
        char = "R"
        className = 'icon-button-main'
        size = '20px'
        text = "HELP!"
      />
    </div>
  )
}

export default Help
