// <-- Used By NewAccounts.js

import React, { useState, useEffect } from 'react'
import Button from '../../UI/Button/Button'
import { useHistory } from 'react-router-dom'
import { createNewCompany, updateCompany } from '../../../services/company'
import { useDispatch, useSelector } from 'react-redux'
// import { useEffect } from 'react/cjs/react.development'
import { setCompanies } from '../../../reducers/companyReducer'
import UiCard from '../../UI/UiCard/UiCard'
import { setNotification } from '../../UI/Notification/notificationReducer'

const AccountForm = ({ formType }) => {
  const history = useHistory()
  const dispatch = useDispatch()


  // get company id from window.location

  // splitting https://... location to array...
  let  companyId = window.location.href.split('/')
  // and pick the last item of array
  companyId = companyId[companyId.length - 1]

  let companyData = useSelector(state => state.companies).filter( company => company.id === companyId)[0]
  if(formType !== 'editAccount') {
    companyId = undefined
    companyData = undefined
  }

  // states
  const [company, setCompany] = useState('')
  const [active, setActive] = useState(false)
  const [name, setName] = useState('')
  const [streetAddress, setStreetAddress] = useState('')
  const [postNro, setPostNro] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [vat, setVat] = useState('')
  const [tel, setTel] = useState('')
  const [email, setEmail] = useState('')

  const currentUser = useSelector(state => state.user)

  // defining states of company if editMode
  useEffect(() => {
    if(companyData) {
      setCompany(companyData.companyName)
      setActive(companyData.active)
      setName(companyData.contactname)
      setStreetAddress(companyData.street)
      setPostNro(companyData.postNro)
      setCity(companyData.city)
      setCountry(companyData.country)
      setVat(companyData.vat)
      setTel(companyData.tel)
      setEmail(companyData.email)
    }
  },[companyData])

  const clearFields = () => {
    setCompany('')
    setName('')
    setStreetAddress('')
    setPostNro('user')
    setEmail('')
    setCity('')
    setCountry('')
    setVat('')
    setTel('')
    setEmail('')
  }
  const submitFormEditAccount = async (e) => {

    e.preventDefault()

    const body = {
      companyName: company,
      contactname: name,
      street: streetAddress,
      postNro: postNro,
      city: city,
      country: country,
      vat: vat,
      tel: tel,
      email: email,
      active: active
    }
    let modCompany // tmp var for User
    modCompany = await updateCompany(currentUser.token, body, companyId)

    if(modCompany){
      dispatch(setNotification({ notification: 'Tilin muokkaus onnistui' }))
      dispatch(setCompanies(currentUser.token, currentUser.company))

      // history.push('/')
    }
    else{
      dispatch(setNotification({
        notification: 'Nimi on varattu, käytä jotain toista nimeä',
        type: 'alert'
      })
      )
    }
  }

  const submitFormNewAccount = async (e) => {
    e.preventDefault()

    // if(validateForm()){ // must DONE LATER!!!!!!!!

    const newAccount = {
      companyName: company,
      contactname: name,
      street: streetAddress,
      postNro: postNro,
      city: city,
      country: country,
      vat: vat,
      tel: tel,
      email: email,
      active: active
    }

    let newCompany // tmp var for User
    newCompany = await createNewCompany(currentUser.token, newAccount)

    if(newCompany){
      dispatch(setNotification({ notification: 'Uusi tili on luotu, luo nyt Yritykselle käyttäjä' }))
      dispatch(setCompanies(currentUser.token, currentUser.company))
      clearFields()
      history.push('/admin/newuser')
    }
    else{
      dispatch(setNotification({
        notification: 'Tili on jo olemassa, käytä jotain toista nimeä',
        type: 'alert'
      })
      )
    }

  }
  const handleInput = (event) => {
    const id = event.target.id
    const val = event.target.value

    switch (id) {

    case 'company':         setCompany(val);            break
    case 'active':          setActive(val);             break
    case 'name':            setName(val);               break
    case 'streetAddress':   setStreetAddress(val);      break
    case 'postNro':         setPostNro(val);            break
    case 'city':            setCity(val);               break
    case 'country':         setCountry(val);            break
    case 'vat':             setVat(val);                break
    case 'tel':             setTel(val);                break
    case 'email':           setEmail(val);              break

    default: return null

    }

  }

  return(
    <UiCard
      headerTitle = {`Muokataan tiliä ${company}`}
    >
      <form className='giftcard-body-container' >
        <p>Tähdellä* merkityt kohdat ovat pakollisia</p>
        {/* Company */}
        <div className="input-group">
          <label htmlFor="company">Yrityksen nimi*</label>
          <input
            id="company"
            name="company"
            data-testid="company"
            type="text"
            value={company}
            onChange={handleInput}
          />
        </div>

        {/* Name */}
        <div className="input-group">
          <label htmlFor="name">Yhteyshenkilön nimi*</label>
          <input
            id="name"
            name="name"
            data-testid="name"
            type="text"
            value={name}
            onChange={handleInput}
          />
        </div>

        {/* Street address */}
        <div className="input-group">
          <label htmlFor="streetAddress">Katuosoite</label>
          <input
            id="streetAddress"
            name="streetAddress"
            data-testid="streetAddress"
            type="text"
            value={streetAddress}
            onChange={handleInput}
          />
        </div>

        {/* postNro */}
        <div className="input-group">
          <label htmlFor="postNro">Postinumero</label>
          <input
            id="postNro"
            name="postNro"
            data-testid="postNro"
            type="text"
            value={postNro}
            onChange={handleInput}
          />
        </div>

        {/* City */}
        <div className="input-group">
          <label htmlFor="city">Paikkakunta</label>
          <input
            id="city"
            name="city"
            data-testid="city"
            type="text"
            value={city}
            onChange={handleInput}
          />
        </div>

        {/* Country */}
        <div className="input-group">
          <label htmlFor="country">Maa</label>
          <input
            id="country"
            name="country"
            data-testid="country"
            type="text"
            value={country}
            onChange={handleInput}
          />
        </div>

        {/* vat */}
        <div className="input-group">
          <label htmlFor="vat">VAT tai y-tunnus</label>
          <input
            id="vat"
            name="vat"
            data-testid="vat"
            type="text"
            value={vat}
            onChange={handleInput}
          />
        </div>

        {/* tel */}
        <div className="input-group">
          <label htmlFor="tel">Puhelin*</label>
          <input
            id="tel"
            name="tel"
            data-testid="tel"
            type="text"
            value={tel}
            onChange={handleInput}
          />
        </div>

        {/* e-mail */}
        <div className="input-group">
          <label htmlFor="email">E-mail*</label>
          <input
            id="email"
            name="email"
            data-testid="email"
            type="text"
            value={email}
            onChange={handleInput}
          />
        </div>

        {/* Active (Boolean) */}
        <div className="input-group">
          <label htmlFor="email">Onko tili aktiivinen</label>
          <select
            id="active"
            name="active"
            data-testid="active"
            type="select"
            value={email}
            onChange={handleInput}
          >
            <option value={active}>{active ? 'Kyllä' : 'Ei'}</option>
            {active
              ? <option value={false}>Ei</option>
              : <option value={true}>Kyllä</option>}

          </select>
        </div>

        {/* Buttons */}
        {formType === 'newAccount' &&
        <div id="user-form-buttons">
          <Button
            id="submit-user-form-button"
            type = "buttonGreen"
            onClick={submitFormNewAccount}
            label = "Luo uusi tili"
            icon = "update"
          />
        </div>
        }
        {formType === 'editAccount' &&
        <div id="user-form-buttons">
          <Button
            id="submit-user-form-button"
            type = "buttonGreen"
            onClick={submitFormEditAccount}
            label = "Päivitä"
            icon = "update"
          />
        </div>
        }
      </form>
    </UiCard>
  )
}

export default AccountForm
