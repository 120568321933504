import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Title from '../../UI/Title/Title'
import IconButton from '../../UI/IconButton/IconButton'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import './home.css'
import socket from '../../../utilities/socketInit'


const Home = () => {

  const token = useSelector(state => state.user.token)
  useEffect(() => {
    console.log(`HOME enter | socket connected: ${ socket.connected }`)
    if (socket.disconnected) {
      console.log('socket connecting...')
      socket.auth.token = token
      socket.connect()
    }
  }, [])

  const history = useHistory()
  // const dispatch = useDispatch()
  // const token = useSelector(state => state.user && state.user.token)

  const Button = (props) => {
    return(
      <div className = "home-button hover-pointer"
        onClick = {() => { history.push(props.link) }}
      >
        <IconButton
          char = {props.char}
          size="40px"
          className = 'icon-button-gray'
        />
        <p>{props.label}</p>
      </div>
    )
  }
  return(
    <div>
      <Title text= "Virtual Approval Project" icon="A"/>
      <div className ="home-buttons-container">
        <Button
          label='Show sessions'
          char = 'k'
          link = '/vraps'
        />
        <Button
          label='Left screen animation'
          char = '7'
          link = '/leftAnimation'
        />
        <Button
          label='Back screen animation'
          char = '6'
          link = '/bgAnimation'
        />
        <Button
          label='Right screen animation'
          char = '8'
          link = '/rightAnimation'
        />

      </div>


    </div>
  )
}

export default Home
