// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react'
import IconButton from '../../../../../../../components/UI/IconButton/IconButton'
import { useDispatch, useSelector } from 'react-redux'
import { setNotification } from '../../../../../../../components/UI/Notification/notificationReducer'
import { deleteVideoError } from '../../../../../reducers/rdc-vraps'

const SpottedErrors = ({ setPlay, activeVideo, setMouseX, setMouseY, setTime, setErrorSpotted, recordId, setSelecterError, selectedError }) => {
  const token = useSelector(state => state.user.token)
  const dispatch = useDispatch()


  const spotTheErrorHandler = (error) => {

    setPlay(false)
    setTime(error.frame === 0 ? 0.0001 : error.frame) // if time is 0, it doesn't work
    setMouseX(error.errorLocation[0])
    setMouseY(error.errorLocation[1])
    setErrorSpotted(true)
    setSelecterError(error._id)

  }


  const deleteErrorSpotHandler = (errorId) => {
    dispatch(setNotification({
      notification: 'Confirm to delete marked defect', // title
      type: 'modal',
      onClickConfirm: () => {
        dispatch(deleteVideoError(token, recordId, activeVideo.id, errorId))
      }
    }
    ))
  }
  // sort videos by date
  let sortedDefects
  try{
    if(activeVideo){
      sortedDefects = activeVideo.defects.length > 1
        ? activeVideo.defects.sort((a, b) => b.time.localeCompare(a.time))
        : activeVideo.defects
    }

  }  catch(error)
  {
    sortedDefects = activeVideo.defects
    console.log('error: ', activeVideo.defects)
  }

  return(
    <>
      <div className = ""/>
      <table>
        <thead>
          <tr className="table-head">
            <th>N:O</th>
            <th>Defect Type</th>
            <th>Surface</th>
            <th>Time</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            activeVideo && sortedDefects.map( function(error,i){
              return(
                <tr
                  key = {error._id}
                  className = {error._id === selectedError ? 'vrap-tr-selected hover-pointer' : 'hover-pointer'}
                >
                  <td onClick = {() => {spotTheErrorHandler(error)}}>
                    {sortedDefects.length - i}
                  </td>
                  <td onClick = {() => {spotTheErrorHandler(error)}}>
                    {error.errorType}
                  </td>
                  <td onClick = {() => {spotTheErrorHandler(error)}}>
                    {error.surfaceClass ? error.surfaceClass : ' -'}
                  </td>
                  <td onClick = {() => {spotTheErrorHandler(error)}}>
                    {error.frame.toFixed(2)} s.
                  </td>
                  <td>
                    <IconButton
                      onClick={() => deleteErrorSpotHandler(error._id)}
                      className = 'icon-button-secondary'
                      char="M" text="Remove"
                      size = '30'
                    />
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </>
  )
}

export default SpottedErrors