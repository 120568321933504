import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from '../components/Private/Home/Home'
import UserAdmin from '../components/Private/UserAdmin/UserAdmin'
import UpdateUser from '../components/Private/UserAdmin/UpdateUser/UpdateUser'
import NewUser from '../components/Private/UserAdmin/NewUser/NewUser'
import InviteUser from '../components/Private/UserAdmin/InviteUser/InviteUser'
import UserProfile from '../components/Private/UserProfile/UserProfile'
import UserTrashcan from '../components/Private/UserAdmin/UserTrashcan/UserTrashcan'
import Footer from '../components/UI/Footer/Footer'
import Vraps from '../Apps/Vraps/Vraps'
import Msg from '../Apps/Messages/Messages/Msg'
import Thread from '../Apps/Messages/Messages/Thread/Thread'
import VrapUnits from '../Apps/Vraps/components/VrapUnits/VrapUnits'


// Account management
import { useSelector } from 'react-redux'
import NavBar from '../components/UI/NavBar/NavBar'
// import ToDo from '../Apps/ToDo/ToDo/ToDo'
import BgAnimation from '../Apps/Vraps/components/BgAnimations/BgAnimation'
import RightAnimation from '../Apps/Vraps/components/BgAnimations/RightAnimation'
import LeftAnimation from '../Apps/Vraps/components/BgAnimations/LeftAnimation'
// import MaximizableView from '../utilities/MaximizableView'
import Session from '../Apps/Vraps/components/UIcardBodyContainers/Session/Session'
import SessionMainCamera from '../Apps/Vraps/components/UIcardBodyContainers/Session/components/SessionMainCamera'
import Session2Camera from '../Apps/Vraps/components/UIcardBodyContainers/Session/components/Session2Camera'
import Session3Camera from '../Apps/Vraps/components/UIcardBodyContainers/Session/components/Session3Camera'

const AdminRoutes = ({ userToDisplay, activeModules }) => {
  const theme = useSelector(state => state.theme)

  return(
    <div className={'routes-container' + theme}>
      {/* <ToDo/> */}
      <Switch>
        {/* <MaximizableView> */}
        <Route exact path="/home">                <NavBar activeModules = {activeModules}/>       <Home />                               <Footer/>  </Route>
        <Route exact path="/">                    <NavBar activeModules = {activeModules}/>       <Home />                               <Footer/>  </Route>
        <Route exact path="/profile">             <NavBar activeModules = {activeModules}/>       <UserProfile />                        <Footer/>  </Route>
        <Route exact path="/admin/user/:id">      <NavBar activeModules = {activeModules}/>       <UpdateUser user={userToDisplay} />    <Footer/>  </Route>
        <Route exact path="/admin/trash">         <NavBar activeModules = {activeModules}/>       <UserTrashcan/>                        <Footer/>  </Route>
        <Route exact path="/admin/users">         <NavBar activeModules = {activeModules}/>       <UserAdmin/>                           <Footer/>  </Route>
        <Route exact path="/admin/newuser">       <NavBar activeModules = {activeModules}/>       <NewUser/>                             <Footer/>  </Route>
        <Route exact path="/admin/invite">        <NavBar activeModules = {activeModules}/>       <InviteUser/>                          <Footer/>  </Route>
        <Route exact path="/vraps">               <NavBar />                                      <Vraps />                              <Footer/>  </Route>
        <Route exact path="/vraps/:id">                                                           <Session />                                       </Route>
        <Route exact path="/maincam/:id">                                                         <SessionMainCamera />                             </Route>
        <Route exact path="/2cam/:id">                                                            <Session2Camera />                                </Route>
        <Route exact path="/3cam/:id">                                                            <Session3Camera />                                </Route>
        <Route exact path="/msg">                  <NavBar />                                     <Msg />                               <Footer/>   </Route>
        <Route exact path="/msg/:id">              <NavBar />                                     <Thread />                            <Footer/>   </Route>
        <Route exact path="/units">                <NavBar />                                     <VrapUnits/>                          <Footer/>   </Route>
        <Route exact path="/bgAnimation">                                                         <BgAnimation/>                                    </Route>
        <Route exact path="/bgAnimation/:id">                                                     <BgAnimation/>                                    </Route>
        <Route exact path="/rightAnimation">                                                      <RightAnimation/>                                 </Route>
        <Route exact path="/rightAnimation/:id">                                                  <RightAnimation/>                                 </Route>
        <Route exact path="/leftAnimation">                                                       <LeftAnimation/>                                  </Route>
        <Route exact path="/leftAnimation/:id">                                                   <LeftAnimation/>                                  </Route>
        {/* </MaximizableView> */}
      </Switch>
    </div>
  )
}


export default AdminRoutes