// form to modify hardware settings
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateUnit } from '../../../VrapUnits/reducers/rdc-vrapUnits'

const HWForm = ({ currentUnit, setHwModal }) => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.user.token)
  const units = useSelector(state => state.vrapUnits)
  let hwOptions = null

  // if hwOptions are not defined in global state ignoring 'error' and using default settings
  console.log('hwOptions: ' + JSON.stringify(currentUnit.hwOptions))
  try{
    hwOptions = currentUnit.hwOptions.motorOptions
  }
  catch (e) {/*pass*/}

  // local hwOptions, updates on ui change
  // global/store hwOptions chaged only after update button is pressed
  const [state, setState] = useState({
    'motorCmd': null,
    'speed':  hwOptions !== null ? hwOptions.speed : 0.005,
    'direction': hwOptions !== null ? hwOptions.direction : 'right',
    'camUrl': hwOptions !== null ? hwOptions.camUrl : 'rtsp:localhost:8554/camera1'
  })

  const submitHandler = () => {
    setHwModal(false)
    const body = { ...currentUnit, hwOptions: { motorOptions: state } }
    dispatch(updateUnit(token, body, currentUnit.id, units))
  }

  // // updating local swSettings
  const updateState = (sw,data) => {
    setState(prevState => {
      switch (sw){
      case 'speed':
        console.log(`updated speed to: ${data}`)
        return { ...prevState, speed: data }
      case 'dir':
        console.log(`updated direction to: ${data}`)
        return { ...prevState, direction: data }
      case 'camUrl':
        console.log(`updated cam url to: ${data}`)
        return { ...prevState, camUrl: data }
      default:
        console.log(`[ERROR] no state: ${sw}`)
        return { ...prevState }
      }
    })
  }

  return(
    <div className="vrap-parameter-form" >
      <form onSubmit={submitHandler}>
        <label>{'Modify Motor and Camera settings'}</label>
        <hr/>
        <div>
          <h4>Motor Settings</h4>
          Speed: <input type="number" min="0.001" max="0.01" name="rotSpeedInput" value={state.speed} required onChange={ (e) => updateState('speed', parseFloat(e.target.value))} />
          <label>
            Right
            <input className="hsf-rinput" type="radio" name="rdirInput" value="right" checked={state.direction === 'right' ? true:false}  onChange={ (e) => updateState('dir', e.target.value)}/>
          </label>
          <label className="hsf-label-radio">
            Left
            <input className="hsf-rinput" type="radio" name="rdirInput" value="left" checked={state.direction === 'left' ? true:false} onChange={ (e) => updateState('dir', e.target.value)}/>
          </label>
          <hr/>
          <h4>Camera Settings</h4>
          Camera URL: <input type="url" name="camUrlInput" defaultValue={state.camUrl} required onChange={ (e) => updateState('camUrl', e.target.value) }/>

          <div className = "flex-row-space-btw">
            {/* <button className = "buttonGreen" type="submit">Apply</button> */}
            <button className = "buttonGreen" onClick={ submitHandler } >Apply</button>
            <button onClick={ () => setHwModal(false)}>Cancel</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default HWForm
